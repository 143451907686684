<template>
  <div class="py-5 bg-white bs rounded-sm d-flex justify-content-between px-4">
    <h4 class="switch-header ml-3 m-0">{{ title }}</h4>
    <div class="d-flex align-items-center">
      <div @click="updateCB" :class="{ active: active }" class="switch-knob">
        <div class="switch-circle"></div>
      </div>
      <i
        v-if="arrowCB"
        @click="arrowCB"
        class="ri-arrow-right-s-line ml-4 faded"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchCard",
  props: {
    active: Boolean,
    updateCB: Function,
    arrowCB: Function,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.switch-header {
  font-size: 20px !important;
  color: #41424d;
}
.switch-knob {
  width: 60px;
  height: 35px;
  border-radius: 17.5px;
  background-color: #f4f6fd;
  transition: 0.3s;
  padding-left: 3px;
  padding-right: 3px;

  &.active {
    background-color: #47bda0;
    .switch-circle {
      transform: translateX(85%);
    }
  }
  .switch-circle {
    height: 29px;
    width: 29px;
    margin-top: 3px;
    border-radius: 100%;
    background-color: white;
    box-shadow: 0px 0px 2px rgba(black, 0.1);
    transition: 0.3s;
  }
}
</style>