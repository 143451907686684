import {
    firebase,
    GET,
    db,
    UPDATE
} from '@/firebase.js'

import Cell from '@/components/Cell/Cell.vue'
import Naver from '@/components/Naver/Naver.vue'
import SwitchCard from '@/components/SwitchCard/SwitchCard.vue'

export default {
    name: 'Profile',
    components: {
        Cell,
        Naver,
        SwitchCard
    },
    data: function () {
        return {
            email: null,
            userId: null,
            university: null,
            fieldOfStudy: null,
            yearOfGraduation: null,
            study_info: {
                location: null,
                field: null,
                grad_year: null
            },
            study_info_old: null,
            causes: [{
                label: 'Work 💼',
                id: 'work',
                picked: false,
                showOptions: false,
                categories: [{
                        label: 'Tech 🕹️',
                        id: 'tech',
                        picked: false
                    },
                    {
                        label: 'HR 🤝',
                        id: 'hr',
                        picked: false
                    },
                    {
                        label: 'Design 📐',
                        id: 'design',
                        picked: false
                    },
                    {
                        label: 'Legal ⚖️',
                        id: 'legal',
                        picked: false
                    }, {
                        label: 'Industrial 🏭',
                        id: 'industrial',
                        picked: false
                    },
                    {
                        label: 'Medical 🥼',
                        id: 'medical',
                        picked: false
                    },
                    {
                        label: 'Finance 📈',
                        id: 'finance',
                        picked: false
                    },
                    {
                        label: 'Marketing 🎥',
                        id: 'marketing',
                        picked: false
                    }, {
                        label: 'Education 📚',
                        id: 'finance',
                        picked: false
                    },
                    {
                        label: 'Other 🔮',
                        id: 'other',
                        picked: false
                    }
                ]
            }, {
                label: 'Products 🧱',
                id: 'products',
                picked: false,
                showOptions: false,
                categories: [{
                        label: 'Tech & Media 🕹️',
                        id: 'tech_and_media',
                        picked: false
                    }, {
                        label: 'Fashion 👔',
                        id: 'fashion',
                        picked: false
                    }, {
                        label: 'Interior 🪑',
                        id: 'interior',
                        picked: false
                    }, {
                        label: 'Personal care 💄',
                        id: 'personal_care',
                        picked: false
                    }, {
                        label: 'Sports 🏓',
                        id: 'sports',
                        picked: false
                    }, {
                        label: 'Food & Beverages 🥝',
                        id: 'food_and_beverages',
                        picked: false
                    }, {
                        label: 'Books 📚',
                        id: 'books',
                        picked: false
                    },
                    {
                        label: 'Other 🔮',
                        id: 'other',
                        picked: false
                    }
                ]
            }, {
                label: 'Investments 💲',
                id: 'investments',
                picked: false,
                showOptions: false,
                categories: [{
                        label: 'Tech 🕹️',
                        id: 'tech',
                        picked: false
                    },
                    {
                        label: 'Industrial 🏭',
                        id: 'industrial',
                        picked: false
                    },
                    {
                        label: 'Legal ⚖️',
                        id: 'legal',
                        picked: false
                    },
                    {
                        label: 'Medical 🥼',
                        id: 'medical',
                        picked: false
                    },
                    {
                        label: 'Finance 📈',
                        id: 'finance',
                        picked: false
                    },
                    {
                        label: 'Marketing 🎥',
                        id: 'marketing',
                        picked: false
                    },
                    {
                        label: 'Other 🔮',
                        id: 'other',
                        picked: false
                    }
                ]
            }],
            occupation: null,
            occupations: [{
                label: 'Studying 🎓',
                id: 'study',
            }, {
                label: 'Working 💼',
                id: 'work',
            }, {
                label: 'Looking for work 🔍',
                id: 'vacant',
            }],
            lockBigBox: false,
            region: null,
            passwordResetSent: false,
            user: null
        }
    },
    methods: {
        signOut: function () {
            firebase.auth().signOut().then(() => {
                // Sign-out successful.
                this.$router.push({
                    path: '/'
                })
            })
        },
        getUserInfo: function (uid) {
            let doc = db.doc('users/' + uid)
            console.log(uid)
            GET(doc).then(user => {
                this.setUserVars(user)
            }).catch(error => {
                console.log(error.code)
                if (error.code === 'permission-denied') this.getUserFallBack()
            })
        },
        getUserFallBack: function () {
            let user = firebase.auth().currentUser;
            let col = db.collection('users').where('uid', '==', user.uid).limit(1)
            GET(col).then((users) => {
                this.setUserVars(users.docs[0])
            })
        },
        toggleSwitchOverlay: function (cause) {
            cause.showOptions = !cause.showOptions
            this.lockBigBox = cause.showOptions
        },
        setUserVars: function (user) {
            this.userId = user.id
            this.email = user.data().email
            this.occupation = user.data().occupation
            if (user.data().study_info) {
                this.study_info = user.data().study_info
                this.study_info_old = Object.assign({}, this.study_info);
            }
            this.region = user.data().region
            this.setCauses(user)
            this.setPickedFromCategories(user.data().work, this.causes[0].categories)
            this.setPickedFromCategories(user.data().products, this.causes[1].categories)
            this.setPickedFromCategories(user.data().investment, this.causes[2].categories)
        },
        setCauses: function (user) {
            user.data().causes.forEach((causeId) => {
                console.log(causeId)
                let cause = this.causes.find(cause => cause.id === causeId)
                cause.picked = true
                this.$forceUpdate()
            })
        },
        setPickedFromCategories: function (userList, list) {
            userList.forEach((categoryId) => {
                let category = list.find(category => category.id === categoryId)
                category.picked = true
                this.$forceUpdate()
            })
        },
        objIsSame: function (obj1, obj2) {
            for (let key of Object.keys(obj1)) {
                if (obj1[key] !== obj2[key]) return false
            }
            return true
        },
        hidePopupView: function () {
            this.$router.push({
                query: ''
            })
        },
        getPickedFromList: function (list) {
            let pickedFromListsIds = []
            let pickedFromLists = list.filter(item => item.picked === true)
            pickedFromLists.forEach(item => pickedFromListsIds.push(item.id))
            return pickedFromListsIds
        },
        updateUserInDB: function () {
            let doc = db.doc('users/' + this.userId)
            let varsToChange = {}
            if (this.occupation === 'study') varsToChange.study_info = this.study_info
            varsToChange.causes = this.getPickedFromList(this.causes)
            varsToChange.work = this.getPickedFromList(this.causes[0].categories)
            varsToChange.products = this.getPickedFromList(this.causes[1].categories)
            varsToChange.investment = this.getPickedFromList(this.causes[2].categories)
            varsToChange.occupation = this.occupation
            varsToChange.region = this.region
            UPDATE(doc, varsToChange).then(() => {
                console.log('Updated', varsToChange)
                this.hidePopupView()
            })
        },
        openOverlay: function (name) {
            const query = {
                ...this.$route.query,
                [name]: true
            };
            this.$router.push({
                query
            })
        },
        resetPassword: function () {
            if (this.passwordResetSent) return
            firebase.auth().sendPasswordResetEmail(this.user.email).then(() => {
                // Email sent.
                console.log('Email sent')
            }).catch(error => {
                // An error happened.
                console.log(error)
            });
            this.passwordResetSent = true
            this.$forceUpdate()
        },
    },
    created: function () {
        firebase.auth().onAuthStateChanged(user => {
            let email = (user.email) ? user.email : user.providerData[0].email
            let provider = (user.providerData[0].providerId === 'google.com') ? 'google' : 'email'
            this.user = {
                uid: user.uid,
                displayName: user.displayName,
                email: email,
                provider: provider
            }
            this.$forceUpdate()
        });
    }
}